import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  List,
  ListItem,
  Center,
  Divider,
  Link,
  Button,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { Link as RouterLink } from "react-router-dom";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../config/const";

function Rewards() {
  const [rewards, setRewards] = useState([]);
  const { user: currentUser } = useAuth();

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${currentUser?.token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/rewards`, config)
      .then((response) => {
        const sortedRewards = response.data.sort((a, b) => {
          // Sort by availability (unavailable last)
          if (a.availability === 0 && b.availability > 0) return 1;
          if (b.availability === 0 && a.availability > 0) return -1;

          // then sort by points
          return b.pointsNeeded - a.pointsNeeded;
        });
        setRewards(sortedRewards);
      })
      .catch((error) => {
        console.error("Error fetching rewards:", error);
      });
  }, [currentUser?.token]);

  const handleDelete = (rewardId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${currentUser?.token}`,
      },
    };
    axios
      .delete(`${API_BASE_URL}/rewards/delete/${rewardId}`, config)
      .then((response) => {
        setRewards((prevRewards) =>
          prevRewards.filter((reward) => reward._id !== rewardId)
        );
      })
      .catch((error) => {
        console.error("Error deleting reward:", error);
      });
  };

  const isAdmin = currentUser && currentUser.role === "admin";
  const bgAvailable = useColorModeValue("gray.600", "gray.700");
  const bgUnavailable = useColorModeValue("gray.900", "gray.900");

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
    >
      <VStack spacing={4} align="stretch">
        <Heading size="lg">
          {isAdmin ? "Rewards Management" : "Rewards"}
        </Heading>

        {isAdmin && (
          <Button rightIcon={<AddIcon />} as={RouterLink} to="/rewards/new">
            Add New Reward
          </Button>
        )}

        {rewards.length > 0 ? (
          <List spacing={3}>
            {rewards.map((reward) => (
              <>
                <ListItem key={reward.slug || reward._id}>
                  <Stack
                    direction={"row"}
                    spacing={4}
                    justify={"stretch"}
                    align={"stretch"}
                  >
                    <Link
                      as={RouterLink}
                      to={
                        reward.availability > 0 || isAdmin
                          ? `/rewards/${reward.slug}`
                          : "#"
                      }
                      bg={reward.availability > 0 ? bgAvailable : bgUnavailable}
                      color={reward.availability > 0 ? "white" : "gray.500"}
                      cursor={
                        reward.availability > 0 || isAdmin
                          ? "pointer"
                          : "default"
                      }
                      p={4}
                      rounded={"lg"}
                      display={"flex"}
                      flex={1}
                      flexDir={"column"}
                      _hover={{
                        bg:
                          reward.availability > 0 ? "gray.500" : bgUnavailable,
                        color: reward.availability > 0 ? "white" : "gray.500",
                      }}
                    >
                      <Heading as={"h2"} size={"md"} mb={2}>
                        {reward.name}
                      </Heading>
                      <Text>{reward.pointsNeeded} Points</Text>
                    </Link>

                    {isAdmin && (
                      <IconButton
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        size={"full"}
                        p={5}
                        colorScheme="linkedin"
                        onClick={() => handleDelete(reward._id)}
                      />
                    )}
                  </Stack>
                  <Stack direction={"column"} spacing={4} justify={"stretch"} align={"stretch"} px={4} py={2}>
                    <Text>{reward.description}</Text>
                    <Text>Availability: {reward.availability}</Text>
                  </Stack>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        ) : (
          <Text>Check back later, there are no rewards yet.</Text>
        )}
      </VStack>
    </Box>
  );
}

export default Rewards;
