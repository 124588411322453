import React from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  useColorModeValue,
  Link,
  Stack,
  Badge,
  Heading,
  Divider,
  HStack,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { formatPhoneNumber } from "../utils/conversions";

const UserProfile = () => {
  const { user } = useAuth();
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const linkColor = useColorModeValue("blue.500", "blue.200");

  return (
    <Box
      p={4}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={bgColor}
      color={textColor}
    >
      <Heading size="lg" mb={2}>
        User Profile
      </Heading>

      <Stack
        direction={{ base: "column", md: "row" }}
        justify={"stretch"}
        spacing={10}
      >
        <VStack spacing={2} align="stretch" justify={"stretch"} flex={1}>
          <Flex justify="flex-start" align={"baseline"} gap={4}>
            <Text fontSize="lg" fontWeight="bold">
              Name:
            </Text>
            <Text fontSize={"md"}>{user.name}</Text>
          </Flex>

          <Flex justify="flex-start" align={"baseline"} gap={4}>
            <Text fontSize="lg" fontWeight={"bold"}>
              Point Balance:
            </Text>
            <Text fontSize={"md"}>{user.rewardsBalance}</Text>
          </Flex>

          <Flex justify="flex-start" align={"baseline"} gap={4}>
            <Text fontSize="lg" fontWeight={"bold"}>
              Role:
            </Text>
            <Badge colorScheme="green" fontSize={"md"}>
              {user.role}
            </Badge>
          </Flex>
        </VStack>

        <VStack spacing={2} align="stretch" flex={1}>
          <Flex justify="flex-start" align={"baseline"} gap={4}>
            <Text fontSize="lg" fontWeight={"bold"}>
              Email:
            </Text>
            <Link href={`mailto:${user.email}`}>
              <Text
                fontSize={"md"}
                color={linkColor}
                _hover={{ textDecoration: "underline" }}
              >
                {user.email}
              </Text>
            </Link>
          </Flex>
          <Divider />

          {user.address?.street && user.address?.city && user.address?.state && user.address?.zip && (
            <Box>
              <Text fontSize="lg" fontWeight={"bold"} mb={2}>
                Address:
              </Text>
              <Box>
                <Text fontSize={"md"}>Street: {user.address.street}</Text>
                <Text fontSize={"md"}>City: {user.address.city}</Text>
                <Text fontSize={"md"}>State: {user.address.state}</Text>
                <Text fontSize={"md"}>Zipcode: {user.address.zip}</Text>
              </Box>
            </Box>
          )}

          {user.phoneNumber && (
            <Flex justify="flex-start" align={"baseline"} gap={4}>
              <Text fontSize="lg" fontWeight={"bold"}>
                Phone:
              </Text>
              <Text fontSize={"md"}>{formatPhoneNumber(user.phoneNumber)}</Text>
            </Flex>
          )}
        </VStack>
      </Stack>
    </Box>
  );
};

export default UserProfile;
