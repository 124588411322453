import React, { useState } from "react";
import axios from "axios";
import { Box, Button, FormControl, FormLabel, Input, useToast, VStack, Link as ChakraLink, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { API_BASE_URL } from "../config/const";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(`${API_BASE_URL}/users/forgot-password`, { email });
      toast({
        title: "Check your email",
        description: "If that email address is in our system, we've sent you a link to reset your password.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to send password reset email.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={bgColor}
      color={textColor}
      maxWidth="400px"
      mx="auto"
      mt={10}
    >
      <Heading as="h2" size="lg" mb={6} textAlign="center">
        Forgot Password
      </Heading>
      <Text mb={4} textAlign="center">
        Enter your email address below and we'll send you a link to reset your password.
      </Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl isRequired>
            <FormLabel>Email Address</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              bg="white"
              color="black"
            />
          </FormControl>
          <Button mt={4} colorScheme="brand" isLoading={isSubmitting} type="submit" color="white">
            Send Reset Link
          </Button>
          <ChakraLink as={RouterLink} to="/login" color="brand.400">
            Back to Login
          </ChakraLink>
        </VStack>
      </form>
    </Box>
  );
};

export default ForgotPassword;
