import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  List,
  ListItem,
  Spinner,
  useToast,
  Flex,
  Icon,
  Text,
  Heading,
  Link,
  Image,
  Badge,
  HStack,
  Divider,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../config/const";
import { CheckCircleIcon } from "@chakra-ui/icons";
import CreateupLogo from "../assets/Createup-Logo-2024.png";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import NewUserForm from "./NewUserForm"; // Import NewUserForm

dayjs.extend(utc);
dayjs.extend(timezone);

const AuthenticateUser = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(() => new URLSearchParams(search).get("userId"));
  const uid = new URLSearchParams(search).get("m");
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [pointsAdded, setPointsAdded] = useState(0);
  const [checkedInMessage, setCheckedInMessage] = useState("");
  const [checkedIn, setCheckedIn] = useState(false);
  const [scannedUser, setScannedUser] = useState(null);

  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  const padding = useBreakpointValue({ base: 2, md: 4 });
  const marginBottom = useBreakpointValue({ base: 4, md: 16 });
  const headingSize = useBreakpointValue({ base: "md", md: "lg" });
  const imageMaxWidth = useBreakpointValue({ base: "80%", md: "50%" });
  const badgeSpacing = useBreakpointValue({ base: 2, md: 4 });
  const badgeWrap = useBreakpointValue({ base: "wrap", md: "nowrap" });
  const modalSize = useBreakpointValue({ base: "full", md: "xl" });
  const buttonBgColor = 'gray.400';

  const fetchUserEvents = useCallback(
    async (id) => {
      setLoading(true);
      try {
        if (id) {
          const response = await axios.get(`${API_BASE_URL}/authenticate/${id}/events`);
          setEvents(response.data);
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "Could not load events for user.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    },
    [toast]
  );

  const fetchScannedUser = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/users/basic-details/${id}`);
        setScannedUser(response.data);
      } catch (error) {
        toast({
          title: "Error",
          description: "Could not load user details.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [toast]
  );

  useEffect(() => {
    const checkUser = async () => {
      if (userId) {
        await fetchScannedUser(userId);
        await fetchUserEvents(userId);
      } else if (uid) {
        // Try to fetch user by uid
        try {
          await fetchScannedUser(uid);
          setUserId(uid); // Set userId to uid if user is found
          await fetchUserEvents(uid);
        } catch (error) {
          // User not found, show NewUserForm
          setScannedUser(null);
        }
      }
    };
    checkUser();
  }, [userId, uid, fetchScannedUser, fetchUserEvents]);

  const handleCheckIn = async (eventId) => {
    setLoading(true);
    try {
      const checkInResponse = await axios.post(
        `${API_BASE_URL}/authenticate/check-in/${eventId}/user/${userId}`
      );
      toast({
        title: "Checked In",
        description: checkInResponse.data.message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      setPointsAdded(checkInResponse.data.pointsAwarded);
      setTotalPoints(checkInResponse.data.totalPoints);
      setCheckedIn(true);
      setCheckedInMessage(
        `You've been checked in and rewarded ${checkInResponse.data.pointsAwarded} points for attending.`
      );

      setEvents(
        events.map((event) =>
          event._id === eventId ? { ...event, checkedIn: true } : event
        )
      );
      setModalOpen(false);
    } catch (error) {
      toast({
        title: "Check-In Failed",
        description:
          error.response?.data?.message || "Could not check into event.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle new user registration
  const handleNewUser = async ({ name, email }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/authenticate/new-user`, {
        uid,
        name,
        email,
      });
      setScannedUser(response.data.user);
      setUserId(response.data.user._id);
      toast({
        title: "Registration Successful",
        description: "You have been registered successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      const id = response.data.user._id;
      console.log('id', id);

      const eventResponse = await axios.get(
        `${API_BASE_URL}/authenticate/${id}/events`
      );

      console.log('eventResponse', eventResponse.data);
      
      setEvents(eventResponse.data);

    } catch (error) {
      toast({
        title: "Registration Failed",
        description:
          error.response?.data?.message || "Could not register the user.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleButton = () => {
    navigate("/login");
  };

  const isToday = (datetime) => {
    const today = dayjs().startOf("day");
    const eventDate = dayjs(datetime).startOf("day");
    return today.isSame(eventDate);
  };

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      minHeight="100vh"
      bg="gray.900"
      color="gray.50"
      p={4}
    >
      <Spinner
        display={loading ? "block" : "none"}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        size="xl"
      />
      <Box
        width={{ base: "100%", md: "auto" }}
        maxW={{ base: "90%", md: "600px" }}
        p={padding}
        mb={marginBottom}
      >
        <Flex
          direction="column"
          align="center"
          mb={marginBottom}
          p={padding}
        >
          <Image
            src={CreateupLogo}
            alt="Createup Logo"
            maxW={imageMaxWidth}
            mb={marginBottom}
          />

          {/* Show NewUserForm if uid is present and scannedUser is not set */}
          {uid && !scannedUser && (
            <Box width="100%">
              <Heading
                size={headingSize}
                mb={4}
                textAlign="center"
              >
                Register New User
              </Heading>
              <Text fontSize={{ base: "md", md: "lg" }} mb={4} textAlign="center">
                Please enter your name and email to register.
              </Text>
              <Text fontSize={{ base: "md", md: "lg" }} mb={8} textAlign="center">
                <strong>NFC Tag ID:</strong> {uid}
              </Text>
              <NewUserForm onSubmit={handleNewUser} />
            </Box>
          )}

          {/* Show welcome message and user details if scannedUser is set */}
          {scannedUser && (
            <Box width="100%">
              <Heading
                size={headingSize}
                mb={marginBottom}
                textAlign="center"
              >
                Welcome, {scannedUser.name}
              </Heading>
              <HStack
                spacing={badgeSpacing}
                mb={marginBottom}
                wrap={badgeWrap}
                justify="center"
              >
                <Badge variant="outline" fontSize={{ base: "0.8em", md: "0.9em" }} colorScheme="brand">
                  ID {scannedUser._id}
                </Badge>
                <Badge variant="outline" fontSize={{ base: "0.8em", md: "0.9em" }} colorScheme="brand">
                  {scannedUser.email}
                </Badge>
                <Badge variant="outline" fontSize={{ base: "0.8em", md: "0.9em" }} colorScheme="brand">
                  Role: {scannedUser.role}
                </Badge>
              </HStack>
              <Divider mb={marginBottom} />
            </Box>
          )}

          {checkedInMessage && (
            <Box width="100%">
              <Heading
                size={headingSize}
                mb={marginBottom}
                textAlign="center"
              >
                Check-In Successful
              </Heading>
              <Text fontSize={{ base: "md", md: "lg" }} color="green.500" textAlign="center">
                {checkedInMessage}
              </Text>
              <Text fontSize={{ base: "md", md: "lg" }} mb={marginBottom} textAlign="center">
                Total Points: {totalPoints}
              </Text>
              <Divider mb={marginBottom} />
            </Box>
          )}

          {scannedUser && (
            <Box width="100%">
              <Text fontSize={{ base: "md", md: "lg" }} textAlign="center">
                {events.length === 0
                  ? "No upcoming events to check into."
                  : "Step 1: Select an event to check into."}
              </Text>
              <Button
                mt={4}
                onClick={() => setModalOpen(true)}
                colorScheme="brand"
                size={{ base: "md", md: "lg" }}
                variant="solid"
                color="white"
                isFullWidth={{ base: true, md: false }}
                isDisabled={
                  loading ||
                  events.length === 0 ||
                  events.every((event) => !isToday(event.datetime)) ||
                  checkedIn
                }
                _disabled={{
                  bg: "gray.600",
                  color: "gray.400",
                  cursor: "not-allowed",
                }}
              >
                Check-In
              </Button>
              <Text fontSize={{ base: "md", md: "lg" }} mt={8} textAlign="center">
                Step 2: Login to access future events and rewards.
              </Text>
              <Button
                mt={4}
                onClick={handleButton}
                colorScheme="brand"
                size={{ base: "md", md: "lg" }}
                variant="outline"
                color="white"
                isFullWidth={{ base: true, md: false }}
              >
                Login
              </Button>
              <Divider mt={8} />
              <Text mt={8} textAlign="center" fontSize={{ base: "md", md: "lg" }}>
                Don't have an account?
              </Text>
              <Link
                href="mailto:paul@omnislashvisual.com"
                color="brand.400"
                textAlign="center"
                display="block"
                mt={2}
                fontSize={{ base: "md", md: "lg" }}
              >
                Reach out to paul@omnislashvisual.com
              </Link>
            </Box>
          )}
        </Flex>
      </Box>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        size={modalSize}
      >
        <ModalOverlay />
        <ModalContent bg="gray.800" color="gray.50" p={4}>
          <ModalHeader>Select an Event to Check-In</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <List spacing={3}>
              {events.map((event) => (
                <ListItem key={event._id}>
                  <Button
                    colorScheme="brand"
                    color="white"
                    isDisabled={!isToday(event.datetime) || event.checkedIn}
                    onClick={() => handleCheckIn(event._id)}
                    _disabled={{
                      bg: "gray.600",
                      color: "gray.400",
                      cursor: "not-allowed",
                    }}
                    width="100%"
                    size={{ base: "md", md: "lg" }}
                  >
                    {event.title} -{" "}
                    {dayjs(event.datetime)
                      .tz("America/New_York")
                      .format("YYYY-MM-DD hh:mm A")}
                  </Button>
                  {event.checkedIn && (
                    <Icon as={CheckCircleIcon} color="green.500" ml={2} />
                  )}
                </ListItem>
              ))}
            </List>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AuthenticateUser;
