import React from "react";
import {
  Box,
  VStack,
  Heading,
  Link,
  HStack,
  Text,
  Divider,
  Image,
  Spacer,
  Button,
  useColorModeValue,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import {
  HomeOutlined,
  UserOutlined,
  CalendarOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { HamburgerIcon } from "@chakra-ui/icons";
import CreateupLogo from "../assets/Createup-Logo-Clean.png";
import { useAuth } from "../context/AuthContext";

const Sidebar = () => {
  const { user, logout } = useAuth();
  const isAdmin = user && user.role === "admin";

  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const navigationLinks = isAdmin
    ? [
        { name: "Home", icon: <HomeOutlined />, route: "/" },
        { name: "Users", icon: <UserOutlined />, route: "/users" },
        { name: "Events", icon: <CalendarOutlined />, route: "/events" },
        { name: "Rewards", icon: <GiftOutlined />, route: "/rewards" },
      ]
    : [
        { name: "Home", icon: <HomeOutlined />, route: "/" },
        { name: "My Events", icon: <CalendarOutlined />, route: "/events" },
        { name: "My Rewards", icon: <GiftOutlined />, route: "/rewards" },
        { name: "Profile", icon: <UserOutlined />, route: `/user/${user.slug}` },
      ];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const SidebarContent = (
    <VStack align="stretch" spacing={4}>
      <Image src={CreateupLogo} alt="Createup Logo" maxW="150px" mb={6} />
      <Heading size="md" mb={4}>
        {isAdmin ? "Admin Panel" : "User Panel"}
      </Heading>
      {navigationLinks.map((link, index) => (
        <React.Fragment key={link.name}>
          <Link
            as={RouterLink}
            to={link.route}
            color={linkColor}
            _hover={{ color: linkHoverColor, textDecoration: "none" }}
            fontWeight="medium"
          >
            <HStack spacing={3}>
              {link.icon}
              <Text>{link.name}</Text>
            </HStack>
          </Link>
          {index < navigationLinks.length - 1 && <Divider my={2} />}
        </React.Fragment>
      ))}
      <Spacer />
      <Text mb={0}>Logged in as</Text>
      <Text mt={0}>
        <strong>{user ? user.name : "Guest"}</strong>
      </Text>
      <Button
        onClick={user ? logout : () => {}}
        colorScheme={user ? "red" : "green"}
      >
        {user ? "Logout" : "Login"}
      </Button>
    </VStack>
  );

  return (
    <>
      {/* Mobile Navigation */}
      <Box
        display={{ base: "flex", md: "none" }}
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={bgColor}
        padding={4}
        borderBottom="1px"
        borderColor={borderColor}
      >
        <Image src={CreateupLogo} alt="Createup Logo" maxW="100px" />
        <IconButton
          icon={<HamburgerIcon />}
          variant="ghost"
          onClick={onOpen}
          aria-label="Open Menu"
        />
      </Box>

      {/* Desktop Sidebar */}
      <Box
        display={{ base: "none", md: "block" }}
        width="20%"
        p={5}
        backgroundColor={bgColor}
        height="100vh"
        borderRight="1px"
        borderColor={borderColor}
        position="fixed"
        left={0}
        top={0}
      >
        {SidebarContent}
      </Box>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg={bgColor}>
          <DrawerCloseButton />
          <DrawerHeader>{isAdmin ? "Admin Panel" : "User Panel"}</DrawerHeader>
          <DrawerBody>{SidebarContent}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Sidebar;
