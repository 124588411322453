import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    50: '#e3f2ff',
    100: '#b3daff',
    200: '#81c2ff',
    300: '#4eaaff',
    400: '#1c92ff',
    500: '#0079e6',
    600: '#005bb4',
    700: '#003e82',
    800: '#002051',
    900: '#000721',
  },
  gray: {
    50: '#f7fafc',
    100: '#edf2f7',
    200: '#e2e8f0',
    300: '#cbd5e0',
    400: '#a0aec0',
    500: '#718096',
    600: '#4a5568',
    700: '#2d3748',
    800: '#1a202c',
    900: '#171923',
  },
  red: {
    50: '#ffe5e5',
    100: '#ffb3b3',
    200: '#ff8080',
    300: '#ff4d4d',
    400: '#ff1a1a',
    500: '#e60000',
    600: '#b40000',
    700: '#820000',
    800: '#510000',
    900: '#210000',
  },
  green: {
    50: '#e6fff2',
    100: '#b3ffd6',
    200: '#80ffba',
    300: '#4dff9e',
    400: '#1aff82',
    500: '#00e66b',
    600: '#00b453',
    700: '#00823b',
    800: '#005123',
    900: '#00210b',
  },
  yellow: {
    50: '#ffffe5',
    100: '#ffffb3',
    200: '#ffff80',
    300: '#ffff4d',
    400: '#ffff1a',
    500: '#e6e600',
    600: '#b4b400',
    700: '#828200',
    800: '#515100',
    900: '#212100',
  },
  blue: {
    50: '#e5f2ff',
    100: '#b3daff',
    200: '#81c2ff',
    300: '#4eaaff',
    400: '#1c92ff',
    500: '#0079e6',
    600: '#005bb4',
    700: '#003e82',
    800: '#002051',
    900: '#000721',
  },
};

const components = {
  Button: {
    baseStyle: {
      borderRadius: 'md',
      _focus: {
        boxShadow: 'none',
      },
    },
    variants: {
      solid: (props) => ({
        bg: `${props.colorScheme}.500`,
        color: 'white',
        _hover: {
          bg: `${props.colorScheme}.600`,
        },
        _active: {
          bg: `${props.colorScheme}.700`,
        },
      }),
      outline: (props) => ({
        borderColor: `${props.colorScheme}.500`,
        color: `${props.colorScheme}.500`,
        _hover: {
          bg: `${props.colorScheme}.100`,
        },
        _active: {
          bg: `${props.colorScheme}.200`,
        },
      }),
    },
  },
  Link: {
    baseStyle: {
      color: 'brand.500',
      _hover: {
        textDecoration: 'underline',
        color: 'brand.600',
      },
    },
  },
  Input: {
    variants: {
      outline: {
        field: {
          borderColor: 'gray.600',
          _hover: {
            borderColor: 'gray.500',
          },
          _focus: {
            borderColor: 'brand.500',
            boxShadow: '0 0 0 1px #0079e6',
          },
        },
      },
    },
  },
  Select: {
    baseStyle: {
      field: {
        borderColor: 'gray.600',
        _hover: {
          borderColor: 'gray.500',
        },
        _focus: {
          borderColor: 'brand.500',
          boxShadow: '0 0 0 1px #0079e6',
        },
      },
    },
  },
  Checkbox: {
    baseStyle: {
      control: {
        bg: 'gray.700',
        borderColor: 'gray.600',
        _hover: {
          borderColor: 'gray.500',
        },
        _checked: {
          bg: 'brand.500',
          borderColor: 'brand.500',
          _hover: {
            bg: 'brand.600',
          },
        },
      },
    },
  },
  Heading: {
    baseStyle: {
      fontWeight: 'bold',
    },
    sizes: {
      xl: {
        fontSize: '4xl',
        lineHeight: '110%',
      },
      lg: {
        fontSize: '3xl',
        lineHeight: '110%',
      },
      md: {
        fontSize: '2xl',
        lineHeight: '110%',
      },
      sm: {
        fontSize: 'xl',
        lineHeight: '110%',
      },
      xs: {
        fontSize: 'lg',
        lineHeight: '110%',
      },
    },
  },
};

const styles = {
  global: (props) => ({
    body: {
      bg: 'gray.900',
      color: 'gray.50',
    },
    '*::placeholder': {
      color: 'gray.400',
    },
    '*, *::before, &::after': {
      borderColor: 'gray.700',
      wordWrap: 'break-word',
    },
  }),
};

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
};

const theme = extendTheme({
  colors,
  components,
  styles,
  config,
});

export default theme;
