import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Flex,
  Divider,
  Image,
  Select,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { API_BASE_URL } from "../config/const";

const RewardDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [reward, setReward] = useState(null);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [editable, setEditable] = useState(false);
  const [updatedReward, setUpdatedReward] = useState({});
  const { user } = useAuth();
  const toast = useToast();

  // Always call hooks at the top level
  const bgColor = useColorModeValue("gray.100", "gray.700");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rewardResponse = await axios.get(`${API_BASE_URL}/rewards/slug/${slug}`, {
          headers: { Authorization: `Bearer ${user?.token}` },
        });
        setReward(rewardResponse.data);
        setUpdatedReward(rewardResponse.data);

        const categoryResponse = await axios.get(`${API_BASE_URL}/categories`, {
          headers: { Authorization: `Bearer ${user?.token}` },
        });
        setCategories(categoryResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Could not load data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchData();
  }, [slug, user?.token, toast]);

  const handleInputChange = (field, value) => {
    if (field === "category" && value === "new") {
      setIsAddingCategory(true);
    } else {
      setUpdatedReward((prev) => ({ ...prev, [field]: value }));
    }
  };

  const addNewCategory = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/categories`,
        { name: newCategory },
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      );
      setCategories([...categories, response.data]);
      setUpdatedReward((prev) => ({ ...prev, category: response.data.name }));
      setIsAddingCategory(false);
      setNewCategory("");
    } catch (error) {
      console.error("Error adding category:", error);
      toast({
        title: "Failed to add category",
        description: error.response?.data.message || "Could not add category.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const saveChanges = async () => {
    try {
      const response = await axios.patch(
        `${API_BASE_URL}/rewards/slug/${slug}`,
        updatedReward,
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      );
      setReward(response.data);
      setEditable(false);
      if (response.data.slug !== slug) {
        navigate(`/rewards/${response.data.slug}`);
      }
      toast({
        title: "Success",
        description: "Reward updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating reward:", error);
      toast({
        title: "Update failed",
        description: "Could not update reward.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!reward) {
    return <Text>Loading...</Text>;
  }

  const isAdmin = user && user.role === "admin";

  return (
    <Box bg={bgColor} p={4} borderRadius={"xl"}>
      <VStack spacing={4} align="stretch">
        {reward.image && (
          <Image
            src={`/${reward.image}`}
            alt={reward.name}
            maxH={"250px"}
            objectFit={"cover"}
            rounded={"md"}
            shadow={"lg"}
            borderColor={"gray.900"}
            borderWidth={"1px"}
          />
        )}

        {editable ? (
          <FormControl>
            <FormLabel>Reward Name</FormLabel>
            <Input
              value={updatedReward.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              size={"lg"}
            />
          </FormControl>
        ) : (
          <Box>
            <Text fontSize="md" fontWeight={"bold"} letterSpacing={0.25}>
              Reward
            </Text>
            <Heading
              fontSize="3xl"
              mb={2}
              fontWeight={"bold"}
              textTransform={"uppercase"}
            >
              {reward.name}
            </Heading>
          </Box>
        )}

        {editable ? (
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Input
              value={updatedReward.description}
              onChange={(e) =>
                handleInputChange("description", e.target.value)
              }
              size={"lg"}
            />
          </FormControl>
        ) : (
          <Box>
            <Text fontSize="md" fontWeight={"bold"} letterSpacing={0.25}>
              Description
            </Text>
            <Text fontSize="xl" mb={2}>
              {reward.description}
            </Text>
          </Box>
        )}

        <Divider />

        <Stack direction={"row"} justify={"space-between"} align={"center"} gap={4}>
          {editable ? (
            <FormControl>
              <FormLabel>Points Needed</FormLabel>
              <Input
                type="number"
                size={"lg"}
                value={updatedReward.pointsNeeded}
                onChange={(e) => handleInputChange("pointsNeeded", e.target.value)}
              />
            </FormControl>
          ) : (
            <Box>
              <Text fontSize="md" fontWeight={"bold"} letterSpacing={0.25}>
                Points Needed
              </Text>
              <Text>{reward.pointsNeeded}</Text>
            </Box>
          )}

          {editable ? (
            <FormControl>
              <FormLabel>Availability</FormLabel>
              <Input
                value={updatedReward.availability}
                size={"lg"}
                onChange={(e) => handleInputChange("availability", e.target.value)}
              />
            </FormControl>
          ) : (
            <Box>
              <Text fontSize="md" fontWeight={"bold"} letterSpacing={0.25}>
                Availability
              </Text>
              <Text>{reward.availability}</Text>
            </Box>
          )}

          {editable ? (
            <FormControl>
              <FormLabel>Vendor</FormLabel>
              <Input
                value={updatedReward.vendor}
                onChange={(e) => handleInputChange("vendor", e.target.value)}
                size={"lg"}
              />
            </FormControl>
          ) : (
            <Box>
              <Text fontSize="md" fontWeight={"bold"} letterSpacing={0.25}>
                Vendor
              </Text>
              <Text>{reward.vendor}</Text>
            </Box>
          )}

          {editable ? (
            <FormControl>
              <FormLabel>Category</FormLabel>
              <Select
                value={updatedReward.category}
                onChange={(e) => handleInputChange("category", e.target.value)}
                placeholder="Select category"
                size={"lg"}
              >
                {categories.map((category) => (
                  <option key={category._id} value={category.name}>
                    {category.name}
                  </option>
                ))}
                <option value="new">+ Add new category</option>
              </Select>
            </FormControl>
          ) : (
            <Box>
              <Text fontSize="md" fontWeight={"bold"} letterSpacing={0.25}>
                Category
              </Text>
              <Text>{reward.category}</Text>
            </Box>
          )}
        </Stack>

        <Modal isOpen={isAddingCategory} onClose={() => setIsAddingCategory(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add New Category</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>New Category Name</FormLabel>
                <Input
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  placeholder="Type category name"
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={addNewCategory}>
                Add Category
              </Button>
              <Button onClick={() => setIsAddingCategory(false)}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Divider />
        {isAdmin && (
          <Flex justifyContent="flex-end">
            <Button colorScheme="blue" onClick={() => setEditable(!editable)} mr={2}>
              {editable ? "Cancel" : "Edit"}
            </Button>
            {editable && (
              <Button colorScheme="green" onClick={saveChanges}>
                Save
              </Button>
            )}
          </Flex>
        )}
      </VStack>
    </Box>
  );
};

export default RewardDetail;
