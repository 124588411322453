import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex, Text, useColorModeValue, Link, Heading, Box } from "@chakra-ui/react";
import { API_BASE_URL } from "../config/const";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const TotalUsers = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const textColor = useColorModeValue("gray.800", "white");
  const { user: currentUser } = useAuth();

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${currentUser?.token}`,
          },
        };
        const response = await axios.get(`${API_BASE_URL}/dashboard/users/count`, config);
        setTotalUsers(response.data.count);
      } catch (error) {
        console.error("Failed to fetch total users", error.response || error.message);
      }
    };

    if (currentUser?.token) {
      fetchTotalUsers();
    }
  }, [currentUser?.token]);

  return (
    <Flex
      p={3}
      shadow="sm"
      borderWidth="1px"
      color={textColor}
      flexDir="column"
      textAlign="center"
      flex={1}
      justify="center"
      rounded="lg"
    >
      <Heading fontSize="3xl" mb={2} fontWeight="bold" textTransform="uppercase">
        Total Users
      </Heading>
      <Box
        as={RouterLink}
        to="/users"
        bg="gray.700"
        p={4}
        rounded="lg"
        display="flex"
        justifyContent="center"
        alignItems="center"
        _hover={{ bg: "gray.500" }}
      >
        <Text fontSize="2xl">{totalUsers}</Text>
      </Box>
    </Flex>
  );
};

export default TotalUsers;
