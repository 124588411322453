import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  List,
  ListItem,
  Text,
  Link,
  Button,
  Flex,
  Stack,
  useToast,
  IconButton,
  useColorModeValue
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import slugify from "slugify";
import { useAuth } from "../context/AuthContext";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../config/const";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { user } = useAuth();
  const toast = useToast();
  const textColor = useColorModeValue("gray.800", "white");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        };
        const response = await axios.get(`${API_BASE_URL}/users?page=${page}&limit=10`, config);
        setUsers(response.data.users);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (user?.token) {
      fetchUsers();
    }
  }, [page, user?.token]);

  const handleDelete = async (userId) => {
    if (!window.confirm("Are you sure you want to delete this user?")) {
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      };
      await axios.delete(`${API_BASE_URL}/users/${userId}`, config);
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
      toast({
        title: "User Deleted",
        description: "The user has been deleted successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting user:", error);
      toast({
        title: "Error Deleting User",
        description: error.response?.data.message || "Failed to delete user",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const isAdmin = user && user.role === "admin";

  const sortedUsers = users.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden" color={textColor}>
      <VStack spacing={4} align="stretch">
        <Heading fontSize="3xl" mb={2} fontWeight="bold" textTransform="uppercase">
          User Management
        </Heading>
        {isAdmin && (
          <Button rightIcon={<AddIcon />} as={RouterLink} to="/users/new" colorScheme="blue">
            Add New User
          </Button>
        )}
        <List spacing={3}>
          {sortedUsers.map((user) => (
            <ListItem key={user._id}>
              <Stack direction="row" spacing={4} justify="stretch" align="stretch">
                <Link
                  as={RouterLink}
                  to={`/users/${slugify(user.name, { lower: true, strict: true })}`}
                  bg="gray.700"
                  color={textColor}
                  p={4}
                  rounded="lg"
                  display="flex"
                  _hover={{ bg: "gray.500" }}
                  flex={2}
                >
                  <Text>{user.name}</Text>
                </Link>
                {isAdmin && (
                  <IconButton
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                    size="full"
                    p={5}
                    colorScheme="red"
                    onClick={() => handleDelete(user._id)}
                  />
                )}
              </Stack>
            </ListItem>
          ))}
        </List>
        <Flex mt={4} justify="space-between" align="center">
          <Button
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
            disabled={page === 1}
            colorScheme="blue"
          >
            Previous
          </Button>
          <Text>{`Page ${page} of ${totalPages}`}</Text>
          <Button
            onClick={() => setPage((prev) => (prev < totalPages ? prev + 1 : prev))}
            disabled={page === totalPages}
            colorScheme="blue"
          >
            Next
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default Users;
