import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Text,
  VStack,
  Heading,
  Divider,
  Badge,
  List,
  ListItem,
  Link,
  Button,
  Stack,
  Flex,
  ButtonGroup,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Center,
  IconButton,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { API_BASE_URL } from "../config/const";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

const UserDetail = () => {
  const { slug } = useParams();
  const [user, setUser] = useState(null);
  const [editable, setEditable] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({});
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { user: currentUser } = useAuth();
  const toast = useToast();

  const bgColor = useColorModeValue("gray.50", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const inputBg = useColorModeValue("white", "gray.700");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const userRes = await axios.get(`${API_BASE_URL}/users/slug/${slug}`, {
          headers: { Authorization: `Bearer ${currentUser?.token}` },
        });
        setUser(userRes.data);
        setUpdatedUser(userRes.data);

        const eventsRes = await axios.get(`${API_BASE_URL}/events`, {
          headers: { Authorization: `Bearer ${currentUser?.token}` },
        });
        setEvents(eventsRes.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Failed to fetch data",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchDetails();
  }, [slug, currentUser?.token, toast]);

  const handleInputChange = (field, value) => {
    setUpdatedUser((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRemoveEvent = (eventId) => {
    setUpdatedUser((prev) => ({
      ...prev,
      eventsAttended: prev.eventsAttended.filter(
        (event) => event._id !== eventId
      ),
    }));
  };

  const handleAddEvent = (event) => {
    if (!updatedUser.eventsAttended.find((e) => e._id === event._id)) {
      setUpdatedUser((prev) => ({
        ...prev,
        eventsAttended: [...prev.eventsAttended, event],
      }));
    }
  };

  const saveChanges = async () => {
    if (!editable) {
      toast({
        title: "Unauthorized",
        description: "You do not have permission to edit this user.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.patch(`${API_BASE_URL}/users/${user._id}`, updatedUser, {
        headers: { Authorization: `Bearer ${currentUser?.token}` },
      });
      setUser(updatedUser);
      setEditable(false);
      toast({
        title: "Success",
        description: "User updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating user:", error);
      toast({
        title: "Update failed",
        description: "Could not update user.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!user) {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bg={bgColor}
      color={textColor}
    >
      <Stack direction={"row"} spacing={4} justify={"space-between"}>
        <Heading size="lg">{editable ? "Edit User" : user.name}</Heading>
        <ButtonGroup isAttached>
          <Button
            colorScheme={editable ? "red" : "green"}
            onClick={() => setEditable(!editable)}
          >
            {editable ? "Cancel" : "Edit"}
          </Button>
          {editable && (
            <Button colorScheme="green" onClick={saveChanges}>
              Save
            </Button>
          )}
        </ButtonGroup>
      </Stack>

      <Stack direction={{ base: "column", md: "row" }} spacing={4} py={4}>
        <VStack spacing={4} align="stretch" flex={1}>
          <FormControl isRequired>
            <FormLabel fontWeight={"bold"} fontSize={"lg"}>Email</FormLabel>
            {editable ? (
              <Input
                bg={inputBg}
                value={updatedUser.email}
                onChange={(e) => handleInputChange("email", e.target.value)}
              />
            ) : (
              <Text>{user.email}</Text>
            )}
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={"bold"} fontSize={"lg"}>Phone</FormLabel>
            {editable ? (
              <Input
                bg={inputBg}
                value={updatedUser.phoneNumber}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
              />
            ) : (
              <Text>{user.phoneNumber || "Not Provided"}</Text>
            )}
          </FormControl>

          <FormControl>
            <FormLabel fontWeight={"bold"} fontSize={"lg"}>Points Balance</FormLabel>
            {editable ? (
              <Input
                bg={inputBg}
                value={updatedUser.rewardsBalance}
                onChange={(e) =>
                  handleInputChange("rewardsBalance", e.target.value)
                }
              />
            ) : (
              <Text>{user.rewardsBalance || "Not Provided"}</Text>
            )}
          </FormControl>

          <Box>
            <Text fontSize="lg" fontWeight={"bold"}>Email Opt-In</Text>
            <Badge
              colorScheme={user.optInStatus ? "green" : "red"}
              rounded={"sm"}
              size={"lg"}
              variant={user.optInStatus ? "outline" : "solid"}
            >
              {user.optInStatus ? "Subscribed" : "Unsubscribed"}
            </Badge>
          </Box>
        </VStack>

        <Center height="250px">
          <Divider orientation="vertical" />
        </Center>
        
        <VStack spacing={4} align="stretch" flex={1}>
          <FormControl>
            <FormLabel fontWeight={"bold"} fontSize={"lg"}>Street</FormLabel>
            {editable ? (
              <Input
                bg={inputBg}
                value={updatedUser.address?.street || ""}
                onChange={(e) =>
                  handleInputChange("address.street", e.target.value)
                }
              />
            ) : (
              <Text>{user.address?.street || "Not Provided"}</Text>
            )}
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={"bold"} fontSize={"lg"}>City</FormLabel>
            {editable ? (
              <Input
                bg={inputBg}
                value={updatedUser.address?.city || ""}
                onChange={(e) =>
                  handleInputChange("address.city", e.target.value)
                }
              />
            ) : (
              <Text>{user.address?.city || "Not Provided"}</Text>
            )}
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={"bold"} fontSize={"lg"}>State</FormLabel>
            {editable ? (
              <Input
                bg={inputBg}
                value={updatedUser.address?.state || ""}
                onChange={(e) =>
                  handleInputChange("address.state", e.target.value)
                }
              />
            ) : (
              <Text>{user.address?.state || "Not Provided"}</Text>
            )}
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={"bold"} fontSize={"lg"}>Zipcode</FormLabel>
            {editable ? (
              <Input
                bg={inputBg}
                value={updatedUser.address?.zip || ""}
                onChange={(e) =>
                  handleInputChange("address.zip", e.target.value)
                }
              />
            ) : (
              <Text>{user.address?.zip || "Not Provided"}</Text>
            )}
          </FormControl>
        </VStack>
      </Stack>

      <Flex direction={"column"} gap={4}>
        <Divider />
        <Heading size="md">Events Attended</Heading>

        <List spacing={3}>
          {updatedUser.eventsAttended.map((event) => (
            <ListItem key={event._id} mb={4}>
              <Stack
                direction={"row"}
                spacing={4}
                justify={"stretch"}
                align={"stretch"}
              >
                <Link
                  as={RouterLink}
                  to={`/events/${event.slug}`}
                  bg={"gray.700"}
                  p={4}
                  rounded={"lg"}
                  display={"flex"}
                  flex={2}
                  _hover={{ bg: "gray.500" }}
                >
                  {event.title} -{" "}
                  {event.date
                    ? new Date(event.date).toLocaleDateString()
                    : "No Date"}
                </Link>

                {editable && (
                  <IconButton
                    aria-label="Delete event"
                    icon={<DeleteIcon />}
                    size={"full"}
                    p={5}
                    colorScheme="linkedin"
                    onClick={() => handleRemoveEvent(event._id)}
                  />
                )}
              </Stack>
            </ListItem>
          ))}
        </List>
        {editable && (
          <Button leftIcon={<AddIcon />} onClick={() => setShowModal(true)}>
            Add Event
          </Button>
        )}

        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Event to User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Select
                placeholder="Select event"
                onChange={(e) =>
                  handleAddEvent(
                    events.find((event) => event._id === e.target.value)
                  )
                }
              >
                {events.map((event) => (
                  <option
                    key={event._id}
                    value={event._id}
                    disabled={updatedUser.eventsAttended.some(
                      (e) => e._id === event._id
                    )}
                  >
                    {event.title}
                  </option>
                ))}
              </Select>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setShowModal(false)}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
};

export default UserDetail;
