import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  Text,
  Input,
  Button,
  Flex,
  Divider,
  FormControl,
  FormLabel,
  useToast,
  Spinner,
  Stack,
  HStack,
  SimpleGrid,
  Icon,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { API_BASE_URL } from "../config/const";
import GuestListModal from "./GuestList";
import { MdPerson } from "react-icons/md";
import { CheckIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const EventDetail = () => {
  const { slug } = useParams();
  const { user } = useAuth();
  const [event, setEvent] = useState(null);
  const [editable, setEditable] = useState(false);
  const [updatedEvent, setUpdatedEvent] = useState({});
  const [users, setUsers] = useState([]);
  const [isGuestListModalOpen, setGuestListModalOpen] = useState(false);
  const toast = useToast();

  const isUserOnGuestList = event?.guestList.includes(user?.id);
  const isUserCheckedIn = event?.checkedInUsers.includes(user?.id);
  const handleGuestListOpen = () => setGuestListModalOpen(true);
  const handleGuestListClose = () => setGuestListModalOpen(false);

  const isAdmin = user && user.role === "admin";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const eventResponse = await axios.get(
          `${API_BASE_URL}/events/slug/${slug}`,
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        );

        const event = eventResponse.data;

        // Convert datetime from UTC to local time zone
        event.datetime = dayjs(event.datetime).tz("America/New_York").format("YYYY-MM-DDTHH:mm");

        let allUsers = [];
        let currentPage = 1;
        let totalPages = 0;
        do {
          const usersResponse = await axios.get(
            `${API_BASE_URL}/users?page=${currentPage}`,
            {
              headers: { Authorization: `Bearer ${user?.token}` },
            }
          );
          allUsers = allUsers.concat(usersResponse.data.users);
          totalPages = usersResponse.data.totalPages;
          currentPage++;
        } while (currentPage <= totalPages);

        setEvent(event);
        setUpdatedEvent(event);
        setUsers(allUsers);

        console.log("Event data:", eventResponse.data);
        console.log("logged in user:", user);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error",
          description: "Could not load event or user data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchData();
  }, [slug, user?.token, toast]);

  const displayGuests = () => {
    if (!event || !users.length) return null;

    return users
      .filter((user) => event.guestList.includes(user._id))
      .map((user) => (
        <Box
          key={user._id}
          p={4}
          shadow="md"
          borderWidth="1px"
          rounded="md"
          bg="gray.600"
        >
          <HStack spacing={4}>
            <Icon as={MdPerson} color="white" />
            <Text fontSize="lg" color="whiteAlpha.900">
              {user.name}
            </Text>
            {event.checkedInUsers.includes(user._id) && (
              <Icon as={CheckIcon} color="green.500" />
            )}
          </HStack>
        </Box>
      ));
  };

  const handleInputChange = (field, value) => {
    const keys = field.split(".");
    const lastKey = keys.pop();
    const lastObj = keys.reduce(
      (obj, key) => (obj[key] = obj[key] || {}),
      updatedEvent
    );

    lastObj[lastKey] = value;
    setUpdatedEvent({ ...updatedEvent });
  };

  const saveChanges = async () => {
    if (!isAdmin) {
      toast({
        title: "Unauthorized",
        description: "You do not have permission to edit this event.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const updatedEventToSave = { ...updatedEvent };

      // Convert local datetime to UTC before saving
      if (updatedEvent.datetime) {
        const localDatetime = dayjs.tz(updatedEvent.datetime, "America/New_York").utc();
        updatedEventToSave.datetime = localDatetime.toISOString();
      }

      const response = await axios.patch(
        `${API_BASE_URL}/events/slug/${slug}`,
        updatedEventToSave,
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      );

      setEvent(response.data);
      setEditable(false);
      toast({
        title: "Success",
        description: "Event updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error updating event:", error);
      toast({
        title: "Update failed",
        description: "Could not update the event.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateGuestList = (newGuestList) => {
    setUpdatedEvent((prev) => ({
      ...prev,
      guestList: newGuestList,
    }));
  };

  const handleCheckIn = async () => {
    if (!isUserOnGuestList) {
      toast({
        title: "Check-In Unavailable",
        description: "You are not on the guest list for this event.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}/events/slug/${slug}/check-in/${user?.id}`,
        {},
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      );
      toast({
        title: "Checked In",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEvent((prev) => ({
        ...prev,
        checkedInUsers: isUserCheckedIn
          ? prev.checkedInUsers.filter((id) => id !== user?.id)
          : [...prev.checkedInUsers, user?.id],
      }));
    } catch (error) {
      console.error("Error checking in:", error);
      toast({
        title: "Check-In Failed",
        description: "Could not check you in, please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (!event) {
    return (
      <Flex justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  return (
    <>
      <Box bg="gray.700" p={4} borderRadius="xl">
        <VStack spacing={4} align="stretch">
          <HStack justifyContent="space-between" align="stretch">
            <Heading
              fontSize="3xl"
              mb={2}
              fontWeight="bold"
              textTransform="uppercase"
              display="flex"
              flex={2}
            >
              {editable ? (
                <Input
                  value={updatedEvent.title || ""}
                  onChange={(e) => handleInputChange("title", e.target.value)}
                />
              ) : (
                event.title
              )}
            </Heading>
            <Flex justifyContent="flex-end">
              {!isAdmin && (
                <Button
                  colorScheme={isUserOnGuestList ? "green" : "red"}
                  onClick={handleCheckIn}
                  isDisabled={!isUserOnGuestList || isUserCheckedIn}
                  mr={2}
                >
                  {isUserOnGuestList
                    ? isUserCheckedIn
                      ? "Checked In"
                      : "Check In"
                    : "Not on Guest List"}
                </Button>
              )}
              {isAdmin && (
                <>
                  <Button
                    colorScheme="blue"
                    onClick={() => setEditable(!editable)}
                    mr={2}
                  >
                    {editable ? "Cancel" : "Edit"}
                  </Button>
                  {editable && (
                    <Button colorScheme="green" onClick={saveChanges}>
                      Save
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </HStack>

          <Divider />
          <Stack direction={{ base: "column", md: "row" }} spacing={4} py={4}>
            <VStack spacing={4} align="stretch" flex={1}>
              <FormControl isDisabled={!editable}>
                <FormLabel>Date and Time</FormLabel>
                {editable ? (
                  <Input
                    type="datetime-local"
                    value={updatedEvent.datetime || ""}
                    onChange={(e) => handleInputChange("datetime", e.target.value)}
                  />
                ) : (
                  <Text>{dayjs(event.datetime).tz("America/New_York").format("YYYY-MM-DD hh:mm A")}</Text>
                )}
              </FormControl>

              <FormControl isDisabled={!editable}>
                <FormLabel>Rewards Points</FormLabel>
                {editable ? (
                  <Input
                    type="number"
                    value={updatedEvent.rewardsQuantity || ""}
                    onChange={(e) =>
                      handleInputChange("rewardsQuantity", e.target.value)
                    }
                  />
                ) : (
                  <Text>{event.rewardsQuantity}</Text>
                )}
              </FormControl>
            </VStack>
            <VStack spacing={4} align="stretch" flex={1}>
              {["street", "city", "state", "zip"].map((part) => (
                <FormControl isDisabled={!editable} key={part}>
                  <FormLabel>
                    {part.charAt(0).toUpperCase() + part.slice(1)}
                  </FormLabel>
                  {editable ? (
                    <Input
                      value={updatedEvent.location?.[part] || ""}
                      onChange={(e) =>
                        handleInputChange(`location.${part}`, e.target.value)
                      }
                    />
                  ) : (
                    <Text>{event.location[part]}</Text>
                  )}
                </FormControl>
              ))}
            </VStack>
          </Stack>
          {editable && (
            <>
              <Divider />
              <Button onClick={handleGuestListOpen} colorScheme="blue">Manage Guest List</Button>
              <GuestListModal
                isOpen={isGuestListModalOpen}
                onClose={handleGuestListClose}
                eventGuestList={updatedEvent?.guestList || []}
                updateGuestList={updateGuestList}
              />
            </>
          )}
        </VStack>
      </Box>
      <Box bg="gray.700" p={4} borderRadius="xl" mt={4}>
        <Heading fontSize="xl" mb={4}>
          Guest List
        </Heading>
        <SimpleGrid columns={2} spacing={4}>
          {event && displayGuests()}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default EventDetail;
