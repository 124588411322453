import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Flex,
  VStack,
  Text,
  useColorModeValue,
  Divider,
  Link,
  Heading,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../config/const";
import { Link as RouterLink } from "react-router-dom";

const RecentEvents = () => {
  const [recentEvents, setRecentEvents] = useState([]);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/dashboard/events/recent`)
      .then((response) => setRecentEvents(response.data))
      .catch((error) => console.error("Failed to fetch recent events", error));
  }, []);

  return (
    <Flex
      p={3}
      shadow="sm"
      borderWidth="1px"
      bg={bgColor}
      color={textColor}
      flexDir={"column"}
      textAlign={"center"}
      flex={1}
      justify={"center"}
      rounded={"lg"}
    >
      <Heading
        fontSize="3xl"
        mb={2}
        fontWeight={"bold"}
        textTransform={"uppercase"}
      >
        Recent Events
      </Heading>
      <VStack
        divider={<Divider />}
        spacing={4}
        align={"stretch"}
        mt={4}
        justify={"flex-start"}
      >
        {recentEvents.map((event) => (
          <Link
            as={RouterLink}
            to={`/events/${event.slug}`}
            key={event._id}
            bg={"gray.700"}
            color={"white"}
            p={4}
            rounded={"lg"}
            display={"flex"}
            _hover={{
              bg: "gray.500",
            }}
          >
            <Text key={event._id}>{event.title}</Text>
          </Link>
        ))}
      </VStack>
    </Flex>
  );
};

export default RecentEvents;
