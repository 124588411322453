import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const formatText = (text) => {
    return text.replace(/-/g, " ").toUpperCase();
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
  const truncatedPathnames = useBreakpointValue({
    base: pathnames.length > 3 ? pathnames.slice(-2) : pathnames,
    md: pathnames,
  });

  return (
    <Breadcrumb
      spacing="8px"
      separator=">"
      overflowX="auto"
      whiteSpace="nowrap"
      paddingY={2}
      paddingX={4}
    >
      {isMobile ? (
        <>
          {pathnames.length > 3 && (
            <BreadcrumbItem>
              <BreadcrumbLink as={RouterLink} to="/">
                HOME
              </BreadcrumbLink>
              <ChevronDownIcon ml={2} />
            </BreadcrumbItem>
          )}
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<ChevronDownIcon />}
              variant="ghost"
              aria-label="Open Breadcrumbs Menu"
            />
            <MenuList>
              {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                return (
                  <MenuItem as={RouterLink} to={to} key={to}>
                    {formatText(value)}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        </>
      ) : (
        truncatedPathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === truncatedPathnames.length - 1;

          return (
            <BreadcrumbItem key={to} isCurrentPage={isLast}>
              <BreadcrumbLink
                as={RouterLink}
                to={to}
                style={{ pointerEvents: isLast ? "none" : "auto" }}
                color={isLast ? "brand.500" : "gray.500"}
                _hover={{ textDecoration: "underline", color: "brand.600" }}
              >
                {formatText(value)}
              </BreadcrumbLink>
            </BreadcrumbItem>
          );
        })
      )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
