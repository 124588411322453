import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../config/const';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

const setupAxiosInterceptors = (token, refreshToken, logout) => {
    axios.interceptors.request.eject(axios.interceptors.request.handlers[0]);
    axios.interceptors.response.eject(axios.interceptors.response.handlers[0]);

    if (token) {
        axios.interceptors.request.use((config) => {
            const tokenExpiry = localStorage.getItem('tokenExpiry');
            const currentTime = Date.now() / 1000;

            // Check if token is close to expiration
            if (tokenExpiry && tokenExpiry < currentTime + 300) { // 300 seconds (5 minutes) buffer
                // Trigger token refresh
                return axios.post(`${API_BASE_URL}/login/refresh-token`, {
                    refreshToken: localStorage.getItem('refreshToken'),
                }).then(({ data }) => {
                    // Update tokens
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    localStorage.setItem('tokenExpiry', data.tokenExpiry);
                    config.headers.Authorization = `Bearer ${data.token}`;
                    return config;
                }).catch(error => {
                    console.error('Token refresh failed:', error);
                    logout();
                    return Promise.reject(error);
                });
            }

            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    }
};


const getUserFromLocalStorage = (logout) => {
  try {
    const savedUser = localStorage.getItem('user');
    const savedToken = localStorage.getItem('token');
    const savedRefreshToken = localStorage.getItem('refreshToken');
    if (savedToken && savedUser) {
      setupAxiosInterceptors(savedToken, savedRefreshToken, logout);
      return JSON.parse(savedUser);
    }
    return null;
  } catch (error) {
    console.error('Error parsing user from localStorage:', error);
    return null;
  }
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const login = (userData) => {
    try {
      const userToSave = {
        ...userData,
        id: userData._id
      };
      localStorage.setItem('user', JSON.stringify(userToSave));
      localStorage.setItem('token', userData.token);
      localStorage.setItem('refreshToken', userData.refreshToken);
      localStorage.setItem('tokenExpiry', userData.tokenExpiry); // Save expiry time
      setupAxiosInterceptors(userData.token, userData.refreshToken, logout);
      setUser(userToSave);

      const tokenExpiry = userData.tokenExpiry;
      const currentTime = Date.now() / 1000;

      if (tokenExpiry && tokenExpiry < currentTime) {
        logout();
        return;
      }
    } catch (error) {
      console.error('Failed to save user or token:', error);
    }
  };

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiry');
    setupAxiosInterceptors(null, null, logout);
    setUser(null);
  }, []);

  useEffect(() => {
    const userFromStorage = getUserFromLocalStorage(logout);
    if (userFromStorage) {
      const tokenExpiry = localStorage.getItem('tokenExpiry');
      const currentTime = Date.now() / 1000;
  
      if (tokenExpiry && tokenExpiry < currentTime) {
        // Token is expired, attempt to refresh it
        axios
          .post(`${API_BASE_URL}/login/refresh-token`, {
            refreshToken: localStorage.getItem('refreshToken'),
          })
          .then(({ data }) => {
            localStorage.setItem('token', data.token);
            localStorage.setItem('refreshToken', data.refreshToken);
            localStorage.setItem('tokenExpiry', data.tokenExpiry);
            setupAxiosInterceptors(data.token, data.refreshToken, logout);
            setUser(userFromStorage);
          })
          .catch((err) => {
            console.error('Token refresh failed:', err);
            logout();
          });
      } else {
        setUser(userFromStorage);
      }
    }
    setIsLoading(false);
  }, [logout]);

  const updateUserState = () => {
    const userFromStorage = getUserFromLocalStorage(logout);
    setUser(userFromStorage);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, updateUserState, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
