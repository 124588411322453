import React, { useState } from "react";
import {
  Flex, Text, VStack, Divider, FormControl, FormLabel, Input,
  Select, Button, Stack, Heading, useColorModeValue, useToast, InputGroup, InputRightElement, Icon
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { API_BASE_URL } from "../config/const";
import { formatPhoneNumber } from "../utils/conversions";
import { usePasswordVisibility } from '../hooks/usePasswordVisibility.js';
import { usePasswordGenerator } from "../hooks/usePasswordGenerator.js"; 
import { US_STATES } from "../config/states.js";


const UserNew = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    rewardsBalance: "100",
    role: "user",
    password: "",
    address: {
      street: "",
      city: "",
      state: "CT",  // Assuming "CT" for Connecticut
      zip: "",
    },
  });
  const textColor = useColorModeValue("gray.800", "white");
  const navigate = useNavigate();
  const toast = useToast();
  const [showPassword, togglePasswordVisibility] = usePasswordVisibility();
  const generatePassword = usePasswordGenerator();


  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "phoneNumber") {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: formattedPhoneNumber
      }));
    } else if (name.startsWith("address.")) {
      const addressKey = name.split('.')[1];
      setFormData(prevFormData => ({
        ...prevFormData,
        address: {
          ...prevFormData.address,
          [addressKey]: value
        }
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword();
    setFormData(prevFormData => ({
      ...prevFormData,
      password: newPassword
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/users`, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      toast({
        title: "User Created",
        description: "A new user has been successfully created.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/users");
    } catch (error) {
      toast({
        title: "Error Creating User",
        description: error.response?.data.message || "Failed to create user",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit}
      p={3}
      shadow="sm"
      borderWidth="1px"
      color={textColor}
      flexDir={"column"}
      textAlign={"center"}
      flex={1}
      justify={"stretch"}
      align={"stretch"}
    >
      <Heading fontSize="3xl" mb={2} fontWeight={'bold'} textTransform={'uppercase'}>
        Create a new User
      </Heading>
      <Stack
        direction="row"
        spacing={4}
        justify="stretch"
        align={"stretch"}
        mb={4}
      >
        <VStack
          divider={<Divider />}
          spacing={4}
          w={"full"}
        >
          <FormControl isRequired>
            <FormLabel>Name</FormLabel>
            <Input type="text" name="name" value={formData.name} onChange={handleChange} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Email</FormLabel>
            <Input type="email" name="email" value={formData.email} onChange={handleChange} />
          </FormControl>

          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Starting Rewards Balance</FormLabel>
            <Input type="number" name="rewardsBalance" value={formData.rewardsBalance} onChange={handleChange} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Role</FormLabel>
            <Select name="role" value={formData.role} onChange={handleChange} borderColor={'gray.600'}>
              <option value="user">User</option>
              <option value="admin">Admin</option>
              <option value={"OG"}>OG</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
            />
            <InputRightElement width="10rem">
              <Button h="1.75rem" size="sm" onClick={togglePasswordVisibility}>
                {showPassword ? <Icon as={ViewOffIcon} /> : <Icon as={ViewIcon} />}
              </Button>
              <Button h="1.75rem" size="sm" ml={2} onClick={handleGeneratePassword}>
                Generate
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>

        </VStack>

        <VStack divider={<Divider />} spacing={4} w={'full'}>
          <FormControl>
            <FormLabel>Street</FormLabel>
            <Input type="text" name="address.street" value={formData.address.street} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>City</FormLabel>
            <Input type="text" name="address.city" value={formData.address.city} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>State</FormLabel>
            <Select name="address.state" value={formData.address.state} onChange={handleChange} borderColor={'gray.600'}>
              {US_STATES.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Zip</FormLabel>
            <Input type="text" name="address.zip" value={formData.address.zip} onChange={handleChange} />
          </FormControl>
        </VStack>
      </Stack>
      <VStack divider={<Divider />} spacing={4}>
        <Button colorScheme="blue" type="submit">Create User</Button>
      </VStack>
    </Flex>
  );
};

export default UserNew;
