import React, { useEffect } from "react";
import {
  Box,
  VStack,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import TotalUsers from "./TotalUsers";
import RecentUsers from "./RecentUsers";
import RecentEvents from "./RecentEvents";
import RecentRewards from "./RecentRewards";
import { useAuth } from "../context/AuthContext";
import UserProfile from "./UserProfile";
import UserEvents from "./UserEvents";

const Dashboard = () => {
  const { user } = useAuth();
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.800", "gray.50");

  const isAdmin = user && user.role === "admin";

  return (
    <Box p={5} backgroundColor={bgColor} minHeight="100vh" color={textColor}>
      <VStack spacing={4} align="stretch">
        <Heading size="lg">Createup User Dashboard</Heading>
        <Text fontSize="lg">Quick Access</Text>
        {isAdmin && (
          <Menu>
            <MenuButton as={Button} rightIcon={<AddIcon />} colorScheme="brand">
              Add New
            </MenuButton>
            <MenuList>
              <MenuItem as="a" href="/users/new">
                User
              </MenuItem>
              <MenuDivider />
              <MenuItem as="a" href="/events/new">
                Event
              </MenuItem>
              <MenuDivider />
              <MenuItem as="a" href="/rewards/new">
                Reward
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={6}
          justify={"space-evenly"}
          mt={6}
        >
          {isAdmin ? (
            <>
              <Stack direction={"column"} flex={1} gap={6}>
                <TotalUsers />
                <RecentUsers />
              </Stack>
              <Stack direction={"column"} flex={1} gap={6}>
                <RecentEvents />
                <RecentRewards />
              </Stack>
            </>
          ) : (
            <Stack direction={"column"} flex={1} gap={6}>
              <UserProfile />
              <UserEvents />
            </Stack>
          )}
        </Stack>
      </VStack>
    </Box>
  );
};

export default Dashboard;
