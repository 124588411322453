import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Flex,
  VStack,
  Text,
  useColorModeValue,
  Divider,
  Link,
  Heading,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../config/const";
import { Link as RouterLink } from "react-router-dom";

const RecentRewards = () => {
  const [recentRewards, setRecentRewards] = useState([]);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/dashboard/rewards/recent`)
      .then((response) => setRecentRewards(response.data))
      .catch((error) => console.error("Failed to fetch recent rewards", error));
  }, []);

  return (
    <Flex
      p={3}
      shadow="sm"
      borderWidth="1px"
      bg={bgColor}
      color={textColor}
      flexDir={"column"}
      textAlign={"center"}
      flex={1}
      justify={"center"}
      rounded={"lg"}
    >
      <Heading
        fontSize="3xl"
        mb={2}
        fontWeight={"bold"}
        textTransform={"uppercase"}
      >
        Recent Rewards
      </Heading>
      <VStack
        divider={<Divider />}
        spacing={4}
        align={"stretch"}
        mt={4}
        justify={"flex-start"}
      >
        {recentRewards.map((reward) => (
          <Link
            as={RouterLink}
            to={`/rewards/${reward.slug}`}
            key={reward._id}
            bg={"gray.700"}
            color={"white"}
            p={4}
            rounded={"lg"}
            display={"flex"}
            _hover={{
              bg: "gray.500",
            }}
          >
            <Text key={reward._id}>{reward.description}</Text>
          </Link>
        ))}
      </VStack>
    </Flex>
  );
};

export default RecentRewards;
