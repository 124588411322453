import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  List,
  ListItem,
  Checkbox,
  Spinner,
  useToast,
  Flex,
  Box,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import { API_BASE_URL } from "../config/const";
import { CheckIcon } from "@chakra-ui/icons";
import { useAuth } from "../context/AuthContext";
import { useParams } from "react-router-dom";

const GuestListModal = ({ isOpen, onClose, eventGuestList, updateGuestList }) => {
  const { slug } = useParams();
  const { user } = useAuth();
  const toast = useToast();

  const [users, setUsers] = useState([]);
  const [event, setEvent] = useState({});
  const [selectedUsers, setSelectedUsers] = useState(new Set(eventGuestList));
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const listItemBg = useColorModeValue('gray.100', 'gray.700');

  useEffect(() => {
    const fetchData = async () => {
      if (!isOpen) return;

      setLoading(true);
      try {
        const eventResponse = await axios.get(`${API_BASE_URL}/events/slug/${slug}`, {
          headers: { Authorization: `Bearer ${user?.token}` },
        });

        const usersResponse = await axios.get(`${API_BASE_URL}/users?page=${currentPage}`, {
          headers: { Authorization: `Bearer ${user?.token}` },
        });

        const eventDetails = eventResponse.data;
        const allUsers = usersResponse.data.users;

        const updatedUsers = allUsers.map((user) => ({
          ...user,
          onGuestList: eventDetails.guestList.includes(user._id),
          checkedIn: eventDetails.checkedInUsers.includes(user._id),
        }));

        setUsers(updatedUsers);
        setTotalPages(usersResponse.data.totalPages || 1);
        setEvent(eventDetails);

        console.log("Event details:", eventDetails);
        console.log("Users:", updatedUsers);

      } catch (error) {
        console.error("Error fetching data:", error);
        toast({
          title: "Error loading data",
          description: "Could not load users or event details.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [toast, isOpen, slug, user?.token, currentPage]);

  const handleSelectUser = (userId) => {
    const newSelectedUsers = new Set(selectedUsers);
    if (newSelectedUsers.has(userId)) {
      newSelectedUsers.delete(userId);
    } else {
      newSelectedUsers.add(userId);
    }
    setSelectedUsers(newSelectedUsers);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleCheckIn = async (userId) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/events/slug/${slug}/check-in/${userId}`,
        {},
        { headers: { Authorization: `Bearer ${user?.token}` } }
      );

      setEvent((prevEvent) => {
        const wasCheckedIn = prevEvent.checkedInUsers.includes(userId);
        const updatedCheckedInUsers = wasCheckedIn
          ? prevEvent.checkedInUsers.filter((id) => id !== userId)
          : [...prevEvent.checkedInUsers, userId];

        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, checkedIn: !wasCheckedIn } : user
          )
        );

        return { ...prevEvent, checkedInUsers: updatedCheckedInUsers };
      });

      toast({
        title: "Success",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling check-in status:", error);
      toast({
        title: "Error checking in",
        description: "Could not toggle check-in status.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSelectAll = () => {
    const newSelectedUsers = new Set(selectedUsers);
    users.forEach((user) => newSelectedUsers.add(user._id));
    setSelectedUsers(newSelectedUsers);
  };

  const handleSelectNone = () => {
    setSelectedUsers(new Set());
  };

  const handleModalClose = () => {
    updateGuestList(Array.from(selectedUsers));
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage Guest List</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner />
            </Flex>
          ) : (
            <>
              <Flex justifyContent="flex-start" gap={2} mb={4}>
                <Button colorScheme="brand" onClick={handleSelectAll}>
                  Select All
                </Button>
                <Button colorScheme="brand" onClick={handleSelectNone}>
                  Select None
                </Button>
              </Flex>
              <List spacing={2}>
                {users.map((user) => (
                  <ListItem
                    key={user._id}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    p={2}
                    bg={listItemBg}
                    borderRadius="md"
                    mb={1}
                  >
                    <Checkbox
                      isChecked={selectedUsers.has(user._id)}
                      onChange={() => handleSelectUser(user._id)}
                      size="lg"
                      mb={2}
                    >
                      {user.name}
                    </Checkbox>
                    <IconButton
                      aria-label="Toggle check-in"
                      icon={<CheckIcon />}
                      colorScheme={user.checkedIn ? "green" : "gray"}
                      onClick={() => handleCheckIn(user._id)}
                      isDisabled={!user.onGuestList}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Box flex="1">
            <Button onClick={handlePrevious} isDisabled={currentPage <= 1} mr={2}>
              Previous
            </Button>
            <Button onClick={handleNext} isDisabled={currentPage >= totalPages}>
              Next
            </Button>
          </Box>
          <Button colorScheme="red" onClick={handleModalClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default GuestListModal;
