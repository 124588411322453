import React, { useState } from 'react';
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  Heading,
  Divider,
  useToast,
  useColorModeValue,
  Select
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config/const';
import { US_STATES } from '../config/states';

const EventNew = () => {
  const [formData, setFormData] = useState({
    title: '',
    datetime: '',
    rewardsQuantity: 0,
    location: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
  });
  const textColor = useColorModeValue('gray.800', 'white');
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const toast = useToast();
  const navigate = useNavigate();

  // Handle changes to form inputs
  const handleChange = e => {
    const { name, value } = e.target;
    if (name.startsWith('location.')) {
      const locationKey = name.split('.')[1];
      setFormData(prevFormData => ({
        ...prevFormData,
        location: {
          ...prevFormData.location,
          [locationKey]: value,
        },
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_BASE_URL}/events`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      toast({
        title: 'Event Created',
        description: 'A new event has been successfully created.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
      navigate('/events');
    } catch (error) {
      toast({
        title: 'Error Creating Event',
        description: error.response?.data.message || 'Failed to create event',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit}
      p={6}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      backgroundColor={bgColor}
      color={textColor}
      flexDir="column"
      textAlign="center"
      flex="1"
      justify="stretch"
      align="stretch"
    >
      <Heading fontSize="3xl" mb={6} fontWeight="bold" textTransform="uppercase">
        Create a New Event
      </Heading>
      <VStack divider={<Divider />} spacing={6} align="stretch">
        <FormControl isRequired>
          <FormLabel>Title</FormLabel>
          <Input type="text" name="title" value={formData.title} onChange={handleChange} />
        </FormControl>
        
        <FormControl isRequired>
          <FormLabel>Date and Time</FormLabel>
          <Input type="datetime-local" name="datetime" value={formData.datetime} onChange={handleChange} />
        </FormControl>

        <FormControl>
          <FormLabel>Rewards Quantity</FormLabel>
          <Input type="number" name="rewardsQuantity" value={formData.rewardsQuantity} onChange={handleChange} />
        </FormControl>

        <VStack divider={<Divider />} spacing={6} w="full">
          <FormControl>
            <FormLabel>Street</FormLabel>
            <Input type="text" name="location.street" value={formData.location.street} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>City</FormLabel>
            <Input type="text" name="location.city" value={formData.location.city} onChange={handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>State</FormLabel>
            <Select 
              name="location.state" 
              value={formData.location.state} 
              onChange={handleChange}
              placeholder="Select a state"
              borderColor={"gray.600"}
            >
              {US_STATES.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Zip</FormLabel>
            <Input type="text" name="location.zip" value={formData.location.zip} onChange={handleChange} />
          </FormControl>
        </VStack>

        <Button colorScheme="brand" type="submit">
          Create Event
        </Button>
      </VStack>
    </Flex>
  );
};

export default EventNew;
