import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import {
  ChakraProvider,
  Flex,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import AuthProvider, { useAuth } from "./context/AuthContext";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Users from "./components/Users";
import Events from "./components/Events";
import Rewards from "./components/Rewards";
import UserDetail from "./components/UserDetail";
import EventDetail from "./components/EventDetail";
import RewardDetail from "./components/RewardDetail";
import Sidebar from "./components/Sidebar";
import UserNew from "./components/UserNew";
import Breadcrumbs from "./components/Breadcrumbs";
import EventNew from "./components/EventNew";
import RewardNew from "./components/RewardNew";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AuthenticateUser from "./components/AuthenticateUser";

const AppContent = () => {
  const { user } = useAuth();
  const location = useLocation();

  const showSidebar =
    user &&
    location.pathname !== "/login" &&
    location.pathname !== "/authenticate";
  const showBreadcrumbs =
    user &&
    location.pathname !== "/login" &&
    location.pathname !== "/authenticate";

  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  return (
    <Flex direction={flexDirection} minHeight="100vh">
      {showSidebar && <Sidebar />}
      <Box
        flex="1"
        p={5}
        ml={{ base: 0, md: "20%" }} // Adjust margin-left for desktop
      >
        {showBreadcrumbs && <Breadcrumbs />}
        <Routes>
          <Route path="/authenticate" element={<AuthenticateUser />} />
          <Route path="/login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/events"
            element={
              <PrivateRoute>
                <Events />
              </PrivateRoute>
            }
          />
          <Route
            path="/rewards"
            element={
              <PrivateRoute>
                <Rewards />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:slug"
            element={
              <PrivateRoute>
                <UserDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/events/:slug"
            element={
              <PrivateRoute>
                <EventDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/rewards/:slug"
            element={
              <PrivateRoute>
                <RewardDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/new"
            element={
              <PrivateRoute>
                <UserNew />
              </PrivateRoute>
            }
          />
          <Route
            path="/events/new"
            element={
              <PrivateRoute>
                <EventNew />
              </PrivateRoute>
            }
          />
          <Route
            path="/rewards/new"
            element={
              <PrivateRoute>
                <RewardNew />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
        </Routes>
      </Box>
    </Flex>
  );
};

const App = () => (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
);

export default App;
