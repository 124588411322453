import { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  List,
  ListItem,
  Link,
  Button,
  Stack,
  IconButton,
  Text,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import { Link as RouterLink } from "react-router-dom";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../config/const";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const Events = () => {
  const [events, setEvents] = useState([]);
  const { user } = useAuth();
  const bgPastEvent = useColorModeValue('gray.400', 'gray.800');
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  const flexDirection = useBreakpointValue({ base: "column", md: "row" });

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/events`)
      .then((response) => {
        const sortedEvents = response.data.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
        setEvents(sortedEvents);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, []);

  const isAdmin = user && user.role === "admin";

  const handleDelete = (eventId) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    };
    axios
      .delete(`${API_BASE_URL}/events/id/${eventId}`, config)
      .then(() => {
        setEvents((prevEvents) => prevEvents.filter((event) => event._id !== eventId));
      })
      .catch((error) => console.error("Error deleting event:", error));
  };

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bg={bgColor}
      color={textColor}
    >
      <VStack spacing={4} align="stretch">
        <Heading size="lg">{isAdmin ? "Event Management" : "Events"}</Heading>

        {isAdmin && (
          <Button rightIcon={<AddIcon />} as={RouterLink} to="/events/new" colorScheme="brand">
            Add New Event
          </Button>
        )}

        <List spacing={3}>
          {events.map((event) => {
            const eventDateTime = dayjs(event.datetime).tz("America/New_York");
            const isPast = eventDateTime.isBefore(dayjs());

            return (
              <ListItem key={event._id}>
                <Stack
                  direction={flexDirection}
                  spacing={4}
                  justify={"stretch"}
                  align={"stretch"}
                >
                  <Link
                    as={RouterLink}
                    to={isAdmin || !isPast ? `/events/${event.slug}` : '#'}
                    bg={isPast ? bgPastEvent : 'gray.600'}
                    color={isPast ? 'gray.500' : 'white'}
                    p={4}
                    rounded={"lg"}
                    display={"flex"}
                    flexDir={"column"}
                    flex={1}
                    _hover={{
                      bg: isPast ? bgPastEvent : "gray.500",
                    }}
                    pointerEvents={isPast && !isAdmin ? 'none' : 'auto'}
                  >
                    <Heading as={'h2'} size={'md'} mb={2}>
                      {event.title}
                    </Heading>
                    <Text>
                      {eventDateTime.format("YYYY-MM-DD hh:mm A")}
                    </Text>
                  </Link>

                  {isAdmin && (
                    <IconButton
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                      size={"full"}
                      p={5}
                      colorScheme="red"
                      onClick={() => handleDelete(event._id)}
                      isDisabled={isPast}
                    />
                  )}
                </Stack>
              </ListItem>
            );
          })}
        </List>
      </VStack>
    </Box>
  );
}

export default Events;
