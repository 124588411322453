import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Flex,
  VStack,
  Text,
  Divider,
  Link,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { API_BASE_URL } from "../config/const";
import { useAuth } from "../context/AuthContext";

const RecentUsers = () => {
  const [recentUsers, setRecentUsers] = useState([]);
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");
  const { user: currentUser } = useAuth();

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${currentUser?.token}`,
      },
    };
    axios
      .get(`${API_BASE_URL}/dashboard/users/recent`, config)
      .then((response) => setRecentUsers(response.data))
      .catch((error) => console.error("Failed to fetch recent users", error));
  }, [currentUser?.token]);

  return (
    <Flex
      p={3}
      shadow="sm"
      borderWidth="1px"
      bg={bgColor}
      color={textColor}
      flexDir={"column"}
      textAlign={"center"}
      flex={1}
      justify={"center"}
      rounded={"lg"}
    >
      <Heading
        fontSize="3xl"
        mb={2}
        fontWeight={"bold"}
        textTransform={"uppercase"}
      >
        Recent Users
      </Heading>
      <VStack
        divider={<Divider />}
        spacing={4}
        align={"stretch"}
        mt={4}
        justify={"flex-start"}
      >
        {recentUsers.map((user) => (
          <Link
            as={RouterLink}
            to={`/users/${user.slug}`}
            key={user._id}
            bg={"gray.700"}
            color={"white"}
            p={4}
            rounded={"lg"}
            display={"flex"}
            _hover={{
              bg: "gray.500",
            }}
          >
            <Text>{user.name}</Text>
          </Link>
        ))}
      </VStack>
    </Flex>
  );
};

export default RecentUsers;
