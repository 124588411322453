import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, FormControl, FormLabel, Input, useToast, VStack, Heading, useColorModeValue } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from "../config/const";

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      toast({
        title: "Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.post(`${API_BASE_URL}/users/reset-password/${token}`, {
        password
      });
      toast({
        title: "Password Reset Successfully",
        description: "You can now log in with your new password.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate('/login');
    } catch (error) {
      toast({
        title: "Failed to reset password",
        description: "Invalid or expired token.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={useColorModeValue('white', 'gray.700')}
      color={useColorModeValue('gray.800', 'white')}
    >
      <Heading as="h1" size="lg" mb={4}>
        Reset Password
      </Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <FormControl isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              bg={useColorModeValue('gray.100', 'gray.800')}
              color={useColorModeValue('gray.800', 'white')}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              bg={useColorModeValue('gray.100', 'gray.800')}
              color={useColorModeValue('gray.800', 'white')}
            />
          </FormControl>
          <Button mt={4} colorScheme="blue" isLoading={false} type="submit">
            Reset Password
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default ResetPassword;
