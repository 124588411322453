import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  useColorModeValue
} from "@chakra-ui/react";
import { API_BASE_URL } from "../config/const";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login, updateUserState, logout } = useAuth();
  const navigate = useNavigate();
  const emailRef = useRef();

  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "white");

  useEffect(() => {
    if (error) {
      emailRef.current?.focus();
    }
  }, [error]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/login`, {
        email,
        password,
      });
      login(response.data);
      updateUserState();
      navigate("/");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid email or password.");
        logout();
      } else {
        setError("An unexpected error occurred.");
      }
      setIsLoading(false);
    }
  };

  return (
    <Box p={5} shadow="md" borderWidth="1px" borderRadius="lg" overflow="hidden" bg={bgColor} color={textColor}>
      <VStack spacing={4} align="stretch">
        <Heading size="lg">Login</Heading>
        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{error}</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" onClick={() => setError("")} />
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input ref={emailRef} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </FormControl>
          <Button mt={4} colorScheme="brand" type="submit" isLoading={isLoading} loadingText="Logging in...">
            Login
          </Button>
          <RouterLink to="/forgot-password" style={{ marginTop: '10px', display: 'block', textAlign: 'center', color: useColorModeValue('blue.500', 'blue.300') }}>
            Forgot Password?
          </RouterLink>
        </form>
      </VStack>
    </Box>
  );
};

export default Login;
