import { useState } from "react";
import { FormControl, FormLabel, Input, VStack, Button } from "@chakra-ui/react";

const NewUserForm = ({ onSubmit }) => {
  // State hooks for form inputs
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the name and email back to the parent component
    onSubmit({ name, email });
  };

  return (
    <VStack as="form" onSubmit={handleSubmit} spacing={4}>
      <FormControl>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </FormControl>

      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </FormControl>

      <Button type="submit" colorScheme="brand">
        Submit
      </Button>
    </VStack>
  );
};

export default NewUserForm;
