import React, { useState, useEffect } from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  Stack,
  Heading,
  useColorModeValue,
  useToast,
  Select,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/const";
import { useAuth } from "../context/AuthContext";

const RewardNew = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    pointsNeeded: "",
    availability: "",
    category: "",
    vendor: "",
  });
  const [categories, setCategories] = useState([]);
  const textColor = useColorModeValue("gray.800", "white");
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuth();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/categories`, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories", error);
        toast({
          title: "Error",
          description: "Could not load categories.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchCategories();
  }, [user, toast]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/rewards`, formData, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });
      toast({
        title: "Reward Created",
        description: "A new reward has been successfully created.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate("/rewards");
    } catch (error) {
      toast({
        title: "Error Creating Reward",
        description: error.response?.data.message || "Failed to create reward",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit}
      p={3}
      shadow="sm"
      borderWidth="1px"
      color={textColor}
      flexDir="column"
      textAlign="center"
      flex={1}
      justify="stretch"
      align="stretch"
    >
      <Heading fontSize="3xl" mb={2} fontWeight="bold" textTransform="uppercase">
        Create a New Reward
      </Heading>
      <Stack direction="column" spacing={4} mb={4}>
        <FormControl isRequired>
          <FormLabel>Name</FormLabel>
          <Input type="text" name="name" value={formData.name} onChange={handleChange} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Description</FormLabel>
          <Input type="text" name="description" value={formData.description} onChange={handleChange} />
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Points Needed</FormLabel>
          <Input type="number" name="pointsNeeded" value={formData.pointsNeeded} onChange={handleChange} />
        </FormControl>

        <FormControl>
          <FormLabel>Availability</FormLabel>
          <Input type="text" name="availability" value={formData.availability} onChange={handleChange} />
        </FormControl>

        <FormControl>
          <FormLabel>Category</FormLabel>
          <Select 
            name="category" 
            value={formData.category} 
            onChange={handleChange} 
            placeholder="Select category"
            borderColor={useColorModeValue("gray.600", "gray.600")}
          >
            {categories.map((category) => (
              <option key={category._id} value={category.name}>
                {category.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Vendor</FormLabel>
          <Input type="text" name="vendor" value={formData.vendor} onChange={handleChange} />
        </FormControl>

        <Button colorScheme="blue" type="submit" mt={4}>
          Create Reward
        </Button>
      </Stack>
    </Flex>
  );
};

export default RewardNew;
