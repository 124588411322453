import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  VStack,
  useColorModeValue,
  Text,
  Heading,
  Spinner,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import { API_BASE_URL } from "../config/const";
import { formatDate } from "../utils/conversions";
import { Link as RouterLink } from "react-router-dom";

const UserEvents = () => {
  const { user } = useAuth();
  const itemBgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const containerBgColor = useColorModeValue("gray.50", "gray.800");
  
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsData = [];
      setLoading(true);

      for (const eventId of user.eventsAttended) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/events/id/${eventId}`
          );
          eventsData.push(response.data);
        } catch (error) {
          console.error("Error fetching event details:", error);
        }
      }

      setEvents(eventsData);
      setLoading(false);
    };

    if (user.eventsAttended && user.eventsAttended.length > 0) {
      fetchEvents();
    } else {
      setLoading(false);
    }
  }, [user]);

  if (loading) {
    return (
      <Flex align="center" justify="center" height="100%">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box
      p={4}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      bg={containerBgColor}
      color={textColor}
    >
      <VStack spacing={3} align="stretch">
        <Heading size="lg" mb={2}>
          Events Attended
        </Heading>
        <List spacing={3}>
          {events.map((event) => (
            <ListItem
              key={event._id}
              bg={itemBgColor}
              p={2}
              borderRadius="md"
            >
              <Link as={RouterLink} to={`/events/${event.slug}`}>
                {event.title} - {formatDate(event.datetime)}
              </Link>
            </ListItem>
          ))}
        </List>
        {events.length === 0 && (
          <Text>No events attended</Text>
        )}
      </VStack>
    </Box>
  );
};

export default UserEvents;
